import { render, staticRenderFns } from "./organization.vue?vue&type=template&id=4a8f8239&scoped=true"
import script from "./organization.vue?vue&type=script&lang=js"
export * from "./organization.vue?vue&type=script&lang=js"
import style0 from "./organization.vue?vue&type=style&index=0&id=4a8f8239&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a8f8239",
  null
  
)

export default component.exports